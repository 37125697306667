import { AuthComponent } from './theme/auth/auth.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { HomeComponent } from './modules/home/home.component';
import { LayoutComponent } from './theme/layout/layout.component';
import { PermissionGuard } from './core/guards/permission/permission.guard';
import { Routes } from '@angular/router';
import { SnapshotCompanyRoutes } from './modules/company/snapshot-company/snapshot.company.routes';
import { query } from '@angular/animations';

export const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'auth',
		component: AuthComponent,
		children: [
			{
				path: 'login',

				loadChildren: () =>
					import('./modules/auth/login/login.routes').then(
						(r) => r.LoginRoutes,
					),
			},
			{
				path: 'register',

				loadChildren: () =>
					import('./modules/auth/register/register.routes').then(
						(r) => r.RegisterRoutes,
					),
			},
			{
				path: 'register/embarcador',
				data: {
					type: 'embarcador',
				},
				loadChildren: () =>
					import('./modules/auth/register/register.routes').then(
						(r) => r.RegisterRoutes,
					),
			},

			{
				path: 'register/transportista',
				data: {
					type: 'transportista',
				},
				loadChildren: () =>
					import('./modules/auth/register/register.routes').then(
						(r) => r.RegisterRoutes,
					),
			},
			{
				path: 'two-factor-auth',
				data: {
					queryParams: true,
				},
				loadChildren: () =>
					import('./modules/auth/two-factor-auth/two-factor-auth.routes').then(
						(r) => r.TwoFactorAuthRoutes,
					),
			},
			{
				path: 'forgot-password',
				data: {
					queryParams: true,
				},
				loadChildren: () =>
					import('./modules/auth/forgot-password/forgot-password.routes').then(
						(r) => r.ForgotPasswordRoutes,
					),
			},
			{
				path: 'reset-password',
				data: {
					queryParams: true,
				},
				loadChildren: () =>
					import('./modules/auth/reset-password/reset-password.routes').then(
						(r) => r.ResetPasswordRoutes,
					),
			},
			{
				path: 'validate-account',
				data: {
					queryParams: true,
				},
				loadChildren: () =>
					import(
						'./modules/auth/validate-account/validate-account.routes'
					).then((r) => r.validateAccountRoutes),
			},
		],
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		// canActivateChild: [PermissionGuard],
		children: [
			{
				path: 'home',
				data: { permission: 'dashboard' },
				loadChildren: () =>
					import('./modules/home/home.routes').then((r) => r.HomeRoutes),
			},
			{
				path: 'records',
				loadChildren: () =>
					import('./modules/records/records.routes').then(
						(r) => r.RecordsRoutes,
					),
			},
			{
				path: 'reports',
				children: [
					{
						path: 'user-reports',
						loadChildren: () =>
							import('./modules/reports/user-reports/user-reports.routes').then(
								(r) => r.UserReportsRoutes,
							),
					},
				],
			},
			{
				path: 'freights',
				children: [
					{
						path: 'list',
						loadChildren: () =>
							import(
								'./modules/freights/list-freights/list-freights.routes'
							).then((r) => r.ListFreightsRoutes),
					},
					{
						path: 'add',
						loadChildren: () =>
							import('./modules/freights/add-freight/add-freight.routes').then(
								(r) => r.AddFreightRoutes,
							),
					},
					{
						path: 'edit',
						loadChildren: () =>
							import('./modules/freights/add-freight/add-freight.routes').then(
								(r) => r.AddFreightRoutes,
							),
					},
					{
						path: 'search',
						loadChildren: () =>
							import(
								'./modules/freights/search-freights/search-freights.routes'
							).then((r) => r.SearchFreightsRoutes),
					},
					{
						path: 'dispatch',
						loadChildren: () =>
							import(
								'@app/modules/freights/dispatch-freight/dispatch-freight.routes'
							).then((r) => r.DispatchFreightRoutes),
					},
					{
						path: 'review',
						loadChildren: () =>
							import(
								'@app/modules/freights/review-freight/review-freight.routes'
							).then((r) => r.ReviewFreightRoutes),
					},
					{
						path: 'dispatch-me',
						loadChildren: () =>
							import(
								'@app/modules/freights/dispatch-to-me/dispatch-to-me.routes'
							).then((r) => r.DispatchToMeRoutes),
					},
					{
						path: 'rate',
						loadChildren: () =>
							import(
								'@app/modules/freights/rate-freight/rate-freight.routes'
							).then((r) => r.RateFreightRoutes),
					},
				],
			},
			{
				path: 'search-companies',
				loadChildren: () =>
					import('./modules/search-companies/search-companies.routes').then(
						(r) => r.SearchCompaniesRoutes,
					),
			},
			{
				path: 'profile',
				loadChildren: () =>
					import('@app/modules/account/profile/profile.routes').then(
						(r) => r.ProfileRoutes,
					),
			},
			{
				path: 'notifications',
				loadChildren: () =>
					import('./modules/notifications/notifications.routes').then(
						(r) => r.NotificationsRoutes,
					),
			},
			{
				path: 'company',
				children: [
					{ path: '', redirectTo: '/company/ratings', pathMatch: 'full' },
					{
						path: 'ratings',

						loadChildren: () =>
							import('./modules/company/ratings/ratings.routes').then(
								(r) => r.RatingsRoutes,
							),
					},
					{
						path: 'contract',

						loadChildren: () =>
							import('./modules/company/contract/contract.routes').then(
								(r) => r.ContractRoutes,
							),
					},
					{
						path: 'company-profile',

						loadChildren: () =>
							import(
								'./modules/company/profile-company/profile-company.routes'
							).then((r) => r.ProfileCompanyRoutes),
					},
					{
						path: 'client-snapshot',

						loadChildren: () =>
							import(
								'./modules/company/snapshot-company/snapshot.company.routes'
							).then((r) => r.SnapshotCompanyRoutes),
					},
					{
						path: 'send-email',
						loadChildren: () =>
							import('./modules/company/send-email/send-email.routes').then(
								(r) => r.SendEmailRoutes,
							),
					},
				],
			},
			{
				path: 'admin',
				children: [
					{
						path: 'users',

						loadChildren: () =>
							import('./modules/users/user-list/user-list.routes').then(
								(r) => r.UserListRoutes,
							),
					},
					{
						path: 'add-user',
						loadChildren: () =>
							import('./modules/users/add-user/add-user.routes').then(
								(r) => r.AddUserRoutes,
							),
					},
					{
						path: 'update-user',
						loadChildren: () =>
							import('./modules/users/edit-user/edit-user.routes').then(
								(r) => r.EditUserRoutes,
							),
					},
				],
			},
			{
				path: 'trucks',
				children: [
					{
						path: 'list',
						loadChildren: () =>
							import('./modules/trucks/trucks-list/trucks-list.routes').then(
								(r) => r.TrucksListRoutes,
							),
					},
					{
						path: 'add',
						loadChildren: () =>
							import('./modules/trucks/add-truck/add-truck.routes').then(
								(r) => r.AddTruckRoutes,
							),
					},
					{
						path: 'update',
						data: {
							queryParams: true,
						},
						loadChildren: () =>
							import('./modules/trucks/update-truck/update-truck.routes').then(
								(r) => r.UpdateTruckRoutes,
							),
					},
				],
			},
			{
				path: 'workflow',
				loadChildren: () =>
					import('./modules/workflow/workflow.routes').then(
						(r) => r.workflowRoutes,
					),
			},
			{ path: '**', redirectTo: 'home' },
		],
	},
];
